var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-card',{attrs:{"color":"#F5F5F5","flat":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('loading',{attrs:{"active":_vm.loading},on:{"update:active":function($event){_vm.loading=$event}}}),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('span',{staticStyle:{"font-family":"'IBM Plex Sans Thai'","font-style":"normal","font-weight":"600","font-size":"24px","line-height":"40px"}},[_vm._v(" ประกาศประชุมวิชาการ ระดับชาติ ")])])],1)],1),_c('v-card',{staticClass:"pa-3 mt-4",attrs:{"color":"#F5F5F5","flat":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-tabs',{attrs:{"align-with-title":""},model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c('v-col',{style:(_vm.e1 == 0
              ? 'border-bottom: 1px solid #2ab3a3'
              : 'border-bottom: 1px solid #2ab3a3'),attrs:{"cols":"12","md":"12","sm":"12"}}),_c('v-tabs-slider',{attrs:{"color":"#2AB3A3"}}),_c('v-tab-item',[_c('v-card',{staticClass:"pa-6",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3"}},[_c('v-text-field',{attrs:{"hide-details":"","prepend-inner-icon":"mdi-magnify","placeholder":"ค้นหาประชุมวิชาการ","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"9","sm":"9"}},[_c('v-row',[_c('v-spacer'),(_vm.e1 == 0)?_c('v-col',{staticClass:"text-right"},[_c('DialogAcademicJournal',{on:{"getAll":_vm.getAll}}),_c('DialogAcademicJournalEdit',{attrs:{"itemEdit":_vm.itemEdit,"openDialogEdit":_vm.openDialogEdit},on:{"getAll":_vm.getAll,"closeDialogEdit":_vm.closeDialogEdit}})],1):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersBrandTh,"items":_vm.items1,"search":_vm.search,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.updatedAt",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDate(item.updatedAt))+" ")]}},{key:"item.content_th",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.content_th)+" ")]}},{key:"item.cover_img_url",fn:function(ref){
              var item = ref.item;
return [_c('v-img',{staticClass:"ma-1",attrs:{"src":item.cover_img_url,"width":"120px"}})]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.ViewNews(item)}}},[_vm._v("mdi-content-copy")]),_c('v-icon',{on:{"click":function($event){return _vm.UpdateNews(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.DeleteItem(item)}}},[_vm._v("mdi-delete")])]}},(_vm.isWindowWidthGreaterThan768)?{key:"body",fn:function(props){return [_c('draggable',{attrs:{"list":props.items,"tag":"tbody"},on:{"change":function($event){return _vm.DragItems1(props.items)}}},_vm._l((props.items),function(item,index){return _c('tr',{key:index},[(_vm.isWindowWidthGreaterThan768)?_c('td',[_c('v-icon',{staticClass:"page__grab-icon",attrs:{"small":""}},[_vm._v(" mdi-arrow-all ")])],1):_vm._e(),_c('td',{staticClass:"text-start"},[_vm._v(" "+_vm._s(_vm.convertDate(item.updatedAt))+" ")]),_c('td',{staticClass:"text-start"},[_c('v-img',{staticClass:"ma-1",attrs:{"src":item.cover_img_url,"width":"120px"}})],1),_c('td',{staticClass:"text-start"},[_vm._v(" "+_vm._s(item.name_th)+" ")]),_c('td',{staticClass:"text-start"},[_vm._v(" "+_vm._s(item.content_th)+" ")]),_c('td',{staticClass:"text-start"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{on:{"click":function($event){return _vm.ViewNews(item)}}},[_vm._v("mdi-content-copy")]),_c('v-icon',{on:{"click":function($event){return _vm.UpdateNews(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.DeleteItem(item)}}},[_vm._v("mdi-delete")])],1)],1)])}),0)]}}:null],null,true)})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }