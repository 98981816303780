<template>
  <div>
    <ManageAcademicConferences1 />
  </div>
</template>
<script>
import ManageAcademicConferences1 from "@/components/ManageAcademicConferences/ManageAcademicConferences1";
export default {
  components: {
    ManageAcademicConferences1,
  },
  created() {},
};
</script>
